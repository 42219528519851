import { RootState } from '@/store';
import { ActionContext } from 'vuex';

export type LoadingStateType = {
  isLoading: boolean,
};

export default {
  namespaced: true,
  state: (): LoadingStateType => ({
    isLoading: false,
  }),
  getters: {
    isLoading: function (state: LoadingStateType): boolean {
      return state.isLoading;
    },
  },
  mutations: {
    setLoading: function (state: LoadingStateType, value: boolean): void {
      state.isLoading = value;
    },
  },
  actions: {
    toggleLoading: function ({ commit, getters }: ActionContext<LoadingStateType, RootState>, value: boolean): void {
      commit('setLoading', value !== undefined ? value : !getters.isLoading);
    },
  },
};
