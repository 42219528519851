/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export function getCookieValue (name: string): any {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    const part = parts.pop();
    if (part) {
      return part.split(';').shift();
    }
    return null;
  }
  return null;
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
export function objectDiff (obj1: any, obj2: any): any[] {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const allKeys = new Set([...keys1, ...keys2]);

  const difference: any = {};

  allKeys.forEach((key: any) => {
    if (obj1[key] !== obj2[key]) {
      difference[key] = {
        oldValue: obj1[key],
        newValue: obj2[key],
      };
    }
  });

  const diffArray: any[] = [];

  Object.keys(difference).forEach((key: any) => {
    diffArray.push({ field: key, oldValue: difference[key].oldValue, newValue: difference[key].newValue });
  });

  return diffArray;
}

export default {
  getCookieValue,
  objectDiff,
};
