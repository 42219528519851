import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Auth, { AuthStateType } from './modules/Auth';
import Loading, { LoadingStateType } from './modules/Loading';
import Drawer, { DrawerStateType } from './modules/Drawer';
import CustomTheme, { StateType } from './modules/CustomTheme';

Vue.use(Vuex);

export type RootState = {
  Auth: AuthStateType,
  Loading: LoadingStateType,
  Drawer: DrawerStateType,
  customTheme: StateType,
};

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  reducer: (state: RootState): any => ({
    auth: state.Auth,
    customTheme: state.customTheme,
  }),
});

const store = new Vuex.Store<RootState>({
  modules: {
    auth: Auth,
    drawer: Drawer,
    loading: Loading,
    customTheme: CustomTheme,
  },
  plugins: [vuexLocal.plugin],
});

store.dispatch('customTheme/loadThemeFromLocalStorage');

export default store;
