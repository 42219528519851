import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import store from '../store';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: store.state.customTheme.customTheme.primary || '#380281',
        secondary: store.state.customTheme.customTheme.secondary || '#7919FA',
        accent: store.state.customTheme.customTheme.accent || '#e1d1f6',
        background: store.state.customTheme.customTheme.background || '#f2edf9',
        info: '#2196F3',
        warning: '#FFB300',
        error: '#F44336',
        success: '#4CAF50',
      },
    },
  },
  lang: {
    locales: {
      // eslint-disable-next-line
      ptbr: require('vuetify/lib/locale/pt').default,
    },
    current: 'ptbr',
  },
});
