import Vue from 'vue';
import Particles from 'particles.vue';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import './util/request';
import 'toastr/toastr.scss';
import './assets/styles/global.scss';
import 'tiptap-vuetify/dist/main.css';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.config.productionTip = false;

Vue.use(Particles);
Vue.use(VueApexCharts);

Vue.component('Apexchart', VueApexCharts);

new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  i18n: i18n,

  // eslint-disable-next-line
  render: (h: any) => h(App)
}).$mount('#app');
